/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { renderLineBreak } from "../helpers"

import Column from "../components/column"
import Container from "../components/container"
import Row from "../components/row"
import Text from "../components/text"
import PageLink from "./page-link"

const ArchitectsBenefitsModule = ({ list, bladePatternData }) => {
  return (
    <div
      sx={{
        py: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
      }}
    >
      <Container>
        <Row
          customStyle={{
            mb: theme => theme.spacing.vertical.sm,
            maxWidth: 400,
          }}
        >
          <Column>
            <Text type="h2" customStyle={{ fontSize: [30, 30, 40] }}>
              {renderLineBreak(bladePatternData.title)}
            </Text>
          </Column>
        </Row>
        <Row
          customStyle={{
            mb: theme => theme.spacing.vertical.lg,
            maxWidth: 400,
          }}
        >
          <Column>
            <Text>{bladePatternData.description.description}</Text>
          </Column>
        </Row>
        {list && list.edges && list.edges.length ? (
          <Row>
            {list.edges.map((designPartner, idx) => (
              <Column
                size={[6, 4, 2, null]}
                key={idx}
                customStyle={{ mb: theme => theme.spacing.vertical.sm }}
              >
                <PageLink
                  link={(designPartner.node.slug =="plant-design-studio") ? `/${designPartner.node.slug}` : `/architects/${designPartner.node.slug}`}
                  type="internal"
                  customStyle={{
                    p: "0!important",
                  }}
                >
                  <img
                    sx={{
                      borderRadius: "50%",
                    }}
                    src={designPartner.node.photo.file.url}
                    alt={designPartner.name}
                  />
                </PageLink>
              </Column>
            ))}
          </Row>
        ) : null}
      </Container>
    </div>
  )
}
ArchitectsBenefitsModule.propTypes = {
  list: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          slug: PropTypes.string,
          photo: PropTypes.shape({
            photo: PropTypes.shape({
              file: PropTypes.shape({
                url: PropTypes.string,
              }),
            }),
          }),
        }),
      })
    ),
  }),
  bladePatternData: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaDestination: PropTypes.string,
    description: PropTypes.object,
    desktopImage: PropTypes.object,
    mobileImages: PropTypes.object,
    contentCards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaDestination: PropTypes.object,
        description: PropTypes.object,
        desktopImage: PropTypes.object,
        mobileImages: PropTypes.object,
      })
    ),
  }),
}
export default ArchitectsBenefitsModule

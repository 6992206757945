/** @jsx jsx */
import { jsx } from "theme-ui"
import chunk from "lodash/chunk"
import PropTypes from "prop-types"

import Carousel from "./carousel"
import ContentCard from "./content-card"
import Column from "./column"
import Row from "./row"

const ModelSlider = ({ data, type }) => {
  const renderContentCard = standardHome => (
    <ContentCard
      baths={standardHome.baths}
      beds={standardHome.beds}
      imageList={
        standardHome.images
          ? [standardHome.images[0]]
          : [standardHome.model[0].images[0]]
      }
      squareFootage={standardHome.squareFootage || standardHome.size}
      title={standardHome.name || standardHome.model.name}
      titleLink={{
        to: `/models/${standardHome.model.slug}`,
        type: "internal",
      }}
      key={standardHome.id}
    />
  )
  const getMobileCarouselContent = () => {
    const carouselContent = []

    if (data.edges) {
      data.edges.forEach(standardHome => {
        if (standardHome.node.images && carouselContent.length < 4) {
          carouselContent.push(renderContentCard(standardHome.node))
        }
      })
    } else {
      data.node.model.forEach(standardHome => {
        if (standardHome.images && carouselContent.length < 4) {
          carouselContent.push(renderContentCard(standardHome))
        }
      })
    }

    return carouselContent
  }
  const getDesktopCarouselContentThreeColumns = () => {
    const carouselContent = []
    if (data.edges) {
      chunk(
        data.edges.filter(standardHome => standardHome.node.images),
        3
      ).forEach((standardHome, index) => {
        carouselContent.push(
          <div
            sx={{ display: "flex", height: "100%" }}
            key={standardHome[0].node.id}
          >
            {standardHome.map(home => (
              <Column key={home.node.id} size={[4]}>
                {renderContentCard(home.node)}
              </Column>
            ))}
          </div>
        )
      })
    } else {
      chunk(
        data.node.model.filter(standardHome => standardHome.images),
        3
      ).forEach((standardHome, index) => {
        carouselContent.push(
          <div sx={{ display: "flex", height: "100%" }} key={standardHome.id}>
            {standardHome.map(home => (
              <Column key={home.id} size={[4]}>
                {renderContentCard(home)}
              </Column>
            ))}
          </div>
        )
      })
    }
    return carouselContent
  }
  const getDesktopCarouselContentTwoColumns = () => {
    const carouselContent = []
    if (data.edges) {
      chunk(
        data.edges.filter(standardHome => standardHome.node.images),
        2
      ).forEach((standardHome, index) => {
        if (standardHome.length === 2) {
          const getColumnSize = columnSize => {
            return {
              flexBasis: `${columnSize * 100}%`,
              maxWidth: `${columnSize * 100}%`,
            }
          }

          carouselContent.push(
            <div
              sx={{ display: "flex", height: "100%" }}
              key={standardHome[0].node.id}
            >
              <div sx={getColumnSize(index % 2 === 0 ? 1 / 3 : 2 / 3)}>
                {renderContentCard(standardHome[0].node)}
              </div>
              <div
                sx={{
                  pl: theme => theme.spacing.horizontal,
                  ...getColumnSize(index % 2 === 0 ? 2 / 3 : 1 / 3),
                }}
              >
                {renderContentCard(standardHome[1].node)}
              </div>
            </div>
          )
        } else if (standardHome.length === 1 && standardHome[0].node.images) {
          carouselContent.push(renderContentCard(standardHome[0].node))
        }
      })
    } else {
      chunk(
        data.node.model.filter(standardHome => standardHome.images),
        2
      ).forEach((standardHome, index) => {
        if (standardHome.length === 2) {
          const getColumnSize = columnSize => {
            return {
              flexBasis: `${columnSize * 100}%`,
              maxWidth: `${columnSize * 100}%`,
            }
          }

          carouselContent.push(
            <div sx={{ display: "flex", height: "100%" }} key={standardHome.id}>
              <div sx={getColumnSize(index % 2 === 0 ? 1 / 3 : 2 / 3)}>
                {renderContentCard(standardHome[0])}
              </div>
              <div
                sx={{
                  pl: theme => theme.spacing.horizontal,
                  ...getColumnSize(index % 2 === 0 ? 2 / 3 : 1 / 3),
                }}
              >
                {renderContentCard(standardHome[1])}
              </div>
            </div>
          )
        } else if (standardHome.length === 1 && standardHome.images) {
          carouselContent.push(renderContentCard(standardHome[0]))
        }
      })
    }

    return carouselContent
  }
  const getDesktopCarouselContent = () => {
    return type === "threeColumns"
      ? getDesktopCarouselContentThreeColumns()
      : getDesktopCarouselContentTwoColumns()
  }

  return (
    <Row customStyle={{ mt: theme => theme.spacing.vertical.md }}>
      <Column customStyle={{ display: ["block", "block", "none"] }}>
        <Carousel type="quaternary">{getMobileCarouselContent()}</Carousel>
      </Column>
      <Column customStyle={{ display: ["none", "none", "block"] }}>
        <Carousel type="quaternary">{getDesktopCarouselContent()}</Carousel>
      </Column>
    </Row>
  )
}

ModelSlider.defaultProps = {
  type: "threeColumns",
}

ModelSlider.propTypes = {
  type: PropTypes.oneOf(["threeColumns", "twoColumns"]),
}

export default ModelSlider

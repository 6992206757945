/** @jsx jsx */
import { Flex, jsx } from "theme-ui"
import { graphql } from "gatsby"
import get from "lodash/get"
import React from "react"
import { findBySlug, renderLineBreak } from "../helpers"

import ArchitectsBenefitsModule from "../components/architects-benefits-module"
import BenefitsImagesModule from "../components/benefits-images-module"
import CTAsModule from "../components/ctas-module"
import Container from "../components/container"
import Column from "../components/column"
import Hero from "../components/hero"
import ModelSlider from "../components/model-slider"
import Page from "../components/page"
import ProjectGalleryModule from "../components/project-gallery-module"
import ProjectUnique from "../components/project-unique"
import ProcessModule from "../components/process-module"
import Row from "../components/row"
import TabbedCards from "../components/tabbed-cards"
import TestimonialsModule from "../components/testimonials-module"
import Text from "../components/text"
import Quote from "../components/design-partner/quote"
import CTA from "../components/cta"

import { paramsCheck } from "../functions/functions"
import { useEffect } from "react"

const LivingHomes = ({ data }) => {
  const [isReady, setIsReady] = React.useState(false)
  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }

  React.useEffect(() => {
    if (checkIfWindow) {
      window.location.href = "/consumers"
      setIsReady(true)
    }
  }, [])
  paramsCheck()
  useEffect(() => {
    document.head.innerHTML += '<meta name="robots" content="noindex">'
  }, []);
  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-hero-module",
    })
    const heroHeights = ["auto", "auto", 555]
    if (
      !heroBladePatternData ||
      !get(heroBladePatternData, "desktopImage.file.url")
    ) {
      return
    }

    const ctaList = heroBladePatternData.ctaTitle
      ? [
          {
            text: heroBladePatternData.ctaTitle,
            type: "secondary",
            link: heroBladePatternData.ctaDestination || "/",
          },
        ]
      : []
    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        body={heroBladePatternData.description.description}
        title={heroBladePatternData.title}
        ctaList={ctaList}
        type="secondary"
      />
    )
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  const renderProcessModule = () => {
    const processTextModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-process-text-module",
    })

    if (!processTextModuleBladePatternData) return

    return (
      <ProcessModule bladePatternData={processTextModuleBladePatternData} />
    )
  }
  const renderTestimonialsModule = () => {
    const testimonialsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-testimonials-module",
    })
    if (!testimonialsBladePatternData) return

    return (
      <TestimonialsModule bladePatternData={testimonialsBladePatternData} />
    )
  }
  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }
  const renderProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-gallery-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        projectsData={data.allContentfulProject}
        customStyle={{ pb: "108%", height: 0 }}
      />
    )
  }

  const renderArchitectsBenefitsModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-benefits-architects-module",
    })

    if (!bladePatternData || !data.allContentfulDesignPartner) return

    return (
      <ArchitectsBenefitsModule
        id={bladePatternData.anchorLink}
        bladePatternData={bladePatternData}
        list={data.allContentfulDesignPartner}
      />
    )
  }

  const renderBenefitsImageModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-benefits-list-module",
    })
    if (!bladePatternData) return
    return (
      <BenefitsImagesModule
        ulStyle={{ color: "white" }}
        id={bladePatternData.anchorLink}
        basicCardList={bladePatternData.contentCards}
      />
    )
  }
  const renderModelSliderBladePattern = () => {
    const modelSliderBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "livinghomes-livinghomes-module",
    })

    if (!modelSliderBladePatternData) return
    let livingHomeModels =
      data.allContentfulModelVariation.edges &&
      data.allContentfulModelVariation.edges.filter(ele => {
        return ele.node.type === "adu-model"
          ? true
          : ele.node.type === "single-family-model"
          ? true
          : ele.node.type === "multi-family-model"
          ? true
          : false
      })

    let GalleryDesign
    if (data.allContentfulModelGallery.edges) {
      GalleryDesign = data.allContentfulModelGallery.edges.find(
        ele => ele.node.slug === "livinghomes-model-gallery"
      )
    }

    // console.log("data",data,modelSliderBladePatternData.title)

    // let livingHomeModels = data.allContentfulCustomDesignerGallery.edges && data.allContentfulModelVariation.edges.filter(ele => {
    //   return ele.node.type === 'adu-model' ? true : ele.node.type === 'single-family-model' ? true : ele.node.type === 'multi-family-model' ? true : false;
    // })

    return (
      <Container customStyle={{ py: theme => theme.spacing.vertical.md }}>
        <Row>
          <Column
            size={[12, 12, 6]}
            customStyle={{
              mb: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                0,
              ],
              ":last-child": { mb: 0 },
            }}
          >
            <Text type="h2">
              {renderLineBreak(modelSliderBladePatternData.title)}
            </Text>
          </Column>
          <Column size={[12, 12, 6]}>
            <Flex
              sx={{
                justifyContent: ["flex-start", "flex-start", "flex-end"],
              }}
            >
              <div>
                {modelSliderBladePatternData.ctaTitle ? (
                  <CTA
                    link={modelSliderBladePatternData.ctaDestination || "/"}
                    type={"secondary"}
                    linkType="internal"
                  >
                    {modelSliderBladePatternData.ctaTitle}
                  </CTA>
                ) : null}
              </div>
            </Flex>
          </Column>
        </Row>
        <Row>
          {/* <ModelSlider data={{ edges: livingHomeModels ? [...livingHomeModels] : [] }} /> */}
          <ModelSlider data={GalleryDesign} />
        </Row>
      </Container>
    )
  }
  const renderAnatomyLivingHomeModule = () => {
    return (
      <div id={data.contentfulBladePattern.anchorLink}>
        <TabbedCards
          titleStyle={{
            textAlign: ["left", "center"],
            mb: theme => [3, theme.spacing.vertical.lg],
            fontSize: [36, 40],
            maxWidth: [300, "100%"],
          }}
          data={data.contentfulBladePattern}
        />
      </div>
    )
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  return isReady && (
    <Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderArchitectsBenefitsModule()}
      {renderBenefitsImageModule()}
      {renderModelSliderBladePattern()}
      {renderTestimonialsModule()}
      {renderAnatomyLivingHomeModule()}
      {renderQuote()}
      {renderProjectGalleryModule()}
      {renderCtaModule()}
      {renderProcessModule()}
      {renderServicesModule()}
    </Page>
  )
}

export default LivingHomes

export const query = graphql`
  query livingHomesPPageQuery {
    contentfulPage(slug: { eq: "livinghomes" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulModelGallery {
      edges {
        node {
          id
          title
          slug
          model {
            id
            name
            model {
              id
              slug
            }
            squareFootage
            baths
            beds
            images {
              fluid {
                src
              }
            }
          }
        }
      }
    }

    allContentfulDesignPartner {
      edges {
        node {
          name
          slug
          photo {
            file {
              fileName
              url
            }
          }
        }
      }
    }

    allContentfulModelVariation(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          baths
          beds
          id
          images {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          model {
            id
            slug
          }
          name
          squareFootage
          type
        }
      }
    }

    allContentfulProject(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
        }
      }
    }
    contentfulBladePattern(slug: { eq: "anatomy-of-a-livinghome" }) {
      contentCards {
        ... on ContentfulBasicCard {
          ctaDestination {
            ctaDestination
          }
          ctaTitle
          description {
            json
          }
          desktopImage {
            fluid(maxHeight: 350, maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          id
          title
        }
      }
      title
      anchorLink
    }
    allContentfulBladePattern(
      filter: { slug: { eq: "livinghomes-quote-module" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
